import React, {useState, useEffect, useRef} from 'react';
import _ from 'underscore';
import './Main.css'
import {
    Link, Outlet
} from "react-router-dom";
import Input from "../../libs/Input";
import MyAutoComplete from "./MyAutocomplete";
import Button from "../../libs/Button";

function addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
}

export function getDefPeriod(opts) {
    let {delta} = opts || {}
    return window.periodStats?.period || dateToOdb(addMonths(new Date(), delta || 0))
}

function dateToOdb(_cd) {
    let cd = new Date(_cd);
    let pub = (v) => v < 10 ? '0' + v : v;
    return [cd.getFullYear(), pub(cd.getMonth() + 1)].join('-')
}

export function getFullPeriods(n1 = 2, n2 = -2) {
    let year = new Date().getFullYear();
    let m = window.m;

    let pushYear = (item1) => {
        _.each(m.from_to(1, 12).reverse(), (item2, ind) => {
            let _year = year + item1
            let _month = item2 > 9 ? item2 : '0' + item2;

            let v = `${_year}-${_month}`
            periods.push({name: v, label: v, _id: v, year: _year, month: _month})
        })
    }
    let periods = []
    pushYear(0)
    _.each(m.from_to(n1, -1), (item1, ind) => {
        pushYear(item1)
    })

    _.each(m.from_to(1, n2), (item1, ind) => {
        pushYear(item1)
    })

    return periods
}

export function incPeriod(value, inc) {
    let currentDate = new Date(value);
    inc = +inc
    if (inc) {
        currentDate.setMonth(currentDate.getMonth() + inc);
    }
    console.log("qqqqq current date", value, currentDate);

    return dateToOdb(currentDate)

}

function Layout2(props) {

    console.log('*........ ## ROOT RENDER', props);
    let startValue = props.value || getDefPeriod();
    let [value, setValue] = useState(startValue)
    let [periods, setPeriods] = useState(getFullPeriods(2024 - new Date().getFullYear()))
    const firstRender = useRef(true);

    useEffect(() => {

        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        props.onChange && props.onChange(value)
    }, [value])


    function deltaPeriod(inc) {
        setValue(incPeriod(value, inc))
    }

    return <div className={'2'}>
        <div className="ib">
            <div style={{marginBottom: '0px', zIndex: 1000}}>
                <Button size={'xs'} color={4} onClick={(cb) => {
                    cb && cb()
                    deltaPeriod(-1)
                }}>Пред
                </Button>
                <Button size={'xs'} color={4} onClick={(cb) => {
                    cb && cb()
                    deltaPeriod(1)
                }}>След
                </Button>
            </div>
            <div className="ib" style={{width: '125px', marginTop: '0px'}}>
                <MyAutoComplete
                    items={periods}
                    value={value}
                    onChange={v => {
                        console.log("on change v", v)

                        setValue(v || getDefPeriod())
                    }}
                ></MyAutoComplete>
            </div>
            <div className="ib" style={{marginLeft: '15px',}}>

            </div>
        </div>
    </div>
}

export default Layout2
